<template>
  <el-card shadow="never">
    <el-form :model="formData" label-width="120px">
      <el-row>
        <el-col :span="8">
          <el-form-item label="借出单号：">
            <el-input v-model.trim="formData.lendOrderCode" placeholder="请输入借出单号" class="form-item" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="备件编码：">
            <el-input v-model.trim="formData.materialCode" placeholder="请输入备件编码" class="form-item" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8"></el-col>
      </el-row>
      <el-row style="padding-bottom: 20px">
        <el-col :span="8">
          <el-form-item label="资产码：">
            <el-input v-model.trim="formData.assetSn" placeholder="请输入资产码(可选)" class="form-item" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="计划大类：">
            <el-input v-model.trim="formData.planCategory" class="form-item" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8"></el-col>
      </el-row>
      <el-row v-for="item in itemList" :key="item.name">
        <el-col :span="8">
          <el-form-item label="检验项目：" class="check-tip">{{ item.name }}</el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="检验结果：">
            <el-radio-group v-model="formData.test2Result">
              <el-radio :label="true">OK</el-radio>
              <el-radio :label="false">NG</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="检验提示：" class="check-tip">{{ item.tip }}</el-form-item>
        </el-col>
      </el-row>
      <el-row style="padding-top: 20px">
        <el-col :span="8">
          <el-form-item label="整体检验结果：">
            <el-radio-group v-model="formData.test2Result">
              <el-radio :label="true">合格</el-radio>
              <el-radio :label="false">不合格</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="处置意见：">
            <el-radio-group v-model="formData.test2Result">
              <el-radio :label="true">放行</el-radio>
              <el-radio :label="false">提报</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="8"></el-col>
      </el-row>
      <el-row>
        <el-col :span="4" style="text-align: right">
          <el-button type="primary" @click="submit" :loading="loading">提交抽查结果</el-button>
        </el-col>
        <el-col :span="19">
          <MessageComponent ref="commitMessage"/>
        </el-col>
      </el-row>
    </el-form>
  </el-card>
</template>

<script>
import MessageComponent from "@/components/MessageComponent";

export default {
  name: "CheckBeforeSortAdd",
  components: {MessageComponent},
  data() {
    return {
      formData: {},
      itemList: [
        {name: '确认数量', tip: '核对数量是否正确'},
        {name: 'PN/FRU/USB接口检查', tip: '这是检验提示这是检验提示这是检验提示这是检验提示'},
        {name: '色差/内部扣齿位置', tip: '图库图例不能直观对比色差'},
        {name: '插口/接头', tip: '插口是否生锈/接头有无错线'},
        {name: '硬盘', tip: '螺丝是否锈蚀/金手指'},
        {name: 'TB-J607Z 电源键', tip: ''},
      ]
    }
  },
  methods: {
    submit() {
      if (!this.formData.lendOrderCode) {
        return this.$refs.commitMessage.showError('请输入借出单号')
      }
    }
  }
}
</script>

<style scoped>
.el-card {
  padding-bottom: 200px;
}

.form-item {
  width: 300px;
}

.check-tip {
  color: orange;
}
</style>